import React from 'react'
import styled from 'styled-components'

export const Title = styled.h1`
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
  font-size: 22px;
  color: ${(p) => (p.$active ? 'blue' : 'gray')};
  cursor: pointer;
`

export const Subtitle = styled.h2`
  text-transform: uppercase;
`

export const NoResults = styled.h3`
  text-transform: uppercase;
`
