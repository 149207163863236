import React, { Component } from 'react'
import Pager from './Pager'
import apiClient from './apiClient'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Image from './atoms/Image'
import { Subtitle, NoResults } from './atoms/Text'
import FilterSection from './atoms/FilterSection'
import ParamControl from './atoms/ParamControl'
import ChallengeContext from './ChallengeContext'

class ImagesScreen extends Component {
  state = {
    images: [],
    totalPages: 0,
    fetching: false,
    params: {
      approved: undefined,
      under18: undefined,
      type: undefined,
      sort: undefined,
      perPage: 6,
      offset: 0,
    },
  }

  static contextType = ChallengeContext

  componentDidMount() {
    this.fetchImages()
  }

  setParam = (name, value) => {
    this.setState((state) => {
      if (value === state.params[name]) {
        value = undefined
      }

      let updatedState = {
        params: {
          ...state.params,
          [name]: value,
        },
      }

      if (name !== 'offset') {
        updatedState.params.offset = 0
      }

      return updatedState
    }, this.fetchImages)
  }

  fetchImages = async () => {
    this.setState({
      fetching: true,
    })

    const imageParams = {
      ...this.state.params,
      challengeId: this.context.selectedChallenge,
    }

    const images = await apiClient.getImages(imageParams)

    let totalImages = 0
    if (images && images[0]) {
      totalImages = images[0].full_count
    }

    this.setState({
      images: images,
      fetching: false,
      totalImages: totalImages,
    })
  }

  approveImage = async (image) => {
    const response = await apiClient.approveImage(image.id)
    if (response) {
      this.setState({
        images: this.state.images.filter((i) => i.id !== image.id),
        totalImages: this.state.totalImages - 1,
      })
    }
  }

  rejectImage = async (image, options = {}) => {
    const response = await apiClient.rejectImage(image.id, options)
    if (response) {
      this.setState({
        images: this.state.images.filter((i) => i.id !== image.id),
        totalImages: this.state.totalImages - 1,
      })
    }
  }

  revertImage = async (image) => {
    const response = await apiClient.revertImage(image.id)
    if (response) {
      this.setState({
        images: this.state.images.filter((i) => i.id !== image.id),
        totalImages: this.state.totalImages - 1,
      })
    }
  }

  handlePageChange = (data) => {
    this.setParam('offset', this.state.params.perPage * data.selected)
  }

  render() {
    const images = this.state.images
    const noImages =
      !this.state.fetching && (images === undefined || images.length === 0)
    const paramState = {
      current: this.state.params,
      setParam: this.setParam,
    }
    const totalPages = Math.ceil(
      this.state.totalImages / this.state.params.perPage
    )

    return (
      <div>
        <FilterSection>
          <Subtitle>Filters</Subtitle>
          <ParamControl {...paramState} param="approved" value={undefined}>
            Awaiting Approval
          </ParamControl>
          <ParamControl {...paramState} param="approved" value={true}>
            Approved
          </ParamControl>
          <ParamControl {...paramState} param="approved" value={false}>
            Unapproved
          </ParamControl>
          <br />
          <ParamControl {...paramState} param="under18" value={true}>
            Under 18
          </ParamControl>
          <ParamControl {...paramState} param="under18" value={false}>
            18 and Over
          </ParamControl>
          <br />
          <ParamControl {...paramState} param="type" value="TEAM_IMAGE">
            Team Photo
          </ParamControl>
          <ParamControl {...paramState} param="type" value="SUBMISSION_IMAGE">
            Submissions
          </ParamControl>
          <ParamControl {...paramState} param="type" value="GALLERY_IMAGE">
            Gallery
          </ParamControl>
        </FilterSection>
        <FilterSection>
          <Subtitle>Sort</Subtitle>
          <ParamControl {...paramState} param="sort" value={undefined}>
            Date Added
          </ParamControl>
          <ParamControl {...paramState} param="sort" value="email">
            Email
          </ParamControl>
        </FilterSection>
        <Pager
          key={totalPages}
          pageCount={isNaN(totalPages) ? 1 : totalPages}
          totalResults={this.state.totalImages}
          onPageChange={this.handlePageChange}
          initialPage={0}
        />
        <ImageList>
          <AnimatePresence>
            {images.map((image) => (
              <FadeOut key={image.id}>
                <Image
                  image={image}
                  onApprove={this.approveImage}
                  onReject={this.rejectImage}
                  onRevert={this.revertImage}
                />
              </FadeOut>
            ))}
            {noImages && <NoResults>No Results</NoResults>}
          </AnimatePresence>
        </ImageList>
      </div>
    )
  }
}

const ImageList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`

const FadeOut = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 0.15 } }}
    exit={{ opacity: 0, transition: { duration: 0.15 } }}
  >
    {children}
  </motion.div>
)

export default ImagesScreen
