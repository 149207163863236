import React from 'react'
import styled from 'styled-components'
import { PageLinkButton } from './Buttons'

export default ({ children, current, setParam, param, value }) => (
  <PageLinkButton
    $active={current[param] === value}
    onClick={() => {
      setParam(param, value)
    }}
  >
    {children}
  </PageLinkButton>
)
