import React, { useRef, useState } from 'react'
import TeamContext from './TeamContext'
import { SmallButton } from './atoms/Buttons'
import Table from './atoms/Table'
import { TextInput, Select } from './atoms/Input'
import getSubmissionInfo from './util/getSubmissionInfo'
import Datetime from 'react-datetime'
import styled from 'styled-components'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'

const SUBTYPES = {
  incomplete: 'Incomplete Submission',
  physical: 'Physical Submission',
  digital: 'Digital Submission',
}

const isValidDate = (date) => {
  return moment(date).isValid()
}

const EditSubmission = ({ submission, numImages, onClose }) => {
  const itemCountRef = useRef()
  const youngPeopleCountRef = useRef()
  const typeRef = useRef()
  const submittedAtRef = useRef()
  const [uploading, setUploading] = useState(undefined)

  const {
    createdAt,
    submittedAt,
    value,
    type: subType,
  } = getSubmissionInfo(submission)

  return (
    <TeamContext.Consumer>
      {(ctx) => {
        const handleSubmit = async (e) => {
          e.preventDefault()

          const itemCount = parseInt(itemCountRef.current.value, 10)
          const youngPeopleCount = parseInt(
            youngPeopleCountRef.current.value,
            10
          )

          const type = typeRef.current.value

          const submittedAtRaw = submittedAtRef.current.value
          let submittedAt = undefined

          if (submittedAtRaw.length > 0) {
            const date = moment(submittedAtRaw)
            if (!date.isValid()) {
              return window.alert('Error, submission date is invalid')
            }
            submittedAt = date.valueOf()
          }

          try {
            const result = await ctx.updateSubmission(submission.id, {
              itemCount,
              youngPeopleCount,
              type,
              submittedAt,
            })
          } catch (error) {
            console.error(error)
            window.alert('Error, submission update failed')
          } finally {
            onClose()
          }
        }

        const handleDelete = async (e) => {
          e.preventDefault()
          const result = window.confirm(
            'Are you sure you wish to delete this submission?'
          )
          if (result) {
            await ctx.deleteSubmission(submission.id)
            onClose()
          } else {
            onClose()
          }
        }

        const handleUpload = async (e) => {
          e.preventDefault()
          const files = e.target.files || []
          let i = 0
          for (let file of files) {
            i += 1
            setUploading(`Uploading ${i} of ${files.length}`)
            try {
              await ctx.addSubmissionImage(submission.id, file)
            } catch {
              alert('There was an error uploading an image')
            }
          }
          setUploading(undefined)
        }

        return (
          <Wrapper className={uploading}>
            <UploadingMessage>{uploading}</UploadingMessage>
            <form onSubmit={handleSubmit}>
              <Table>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{submission.id}</td>
                  </tr>
                  <tr>
                    <td>Date Created:</td>
                    <td>{createdAt}</td>
                  </tr>
                  <tr>
                    <td>Date Submitted:</td>
                    <td>
                      <Datetime
                        defaultValue={submittedAt}
                        renderInput={(props, openCalendar, closeCalendar) => {
                          return (
                            <TextInput
                              {...props}
                              ref={submittedAtRef}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault()
                                  closeCalendar()
                                }
                              }}
                            />
                          )
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Total Items:</td>
                    <td>
                      <TextInput
                        ref={itemCountRef}
                        name="itemCount"
                        defaultValue={submission.itemCount}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Total Young People</td>
                    <td>
                      <TextInput
                        ref={youngPeopleCountRef}
                        name="youngPeopleCount"
                        defaultValue={submission.youngPeopleCount}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Total Digital Images</td>
                    <td>{numImages}</td>
                  </tr>
                  <tr>
                    <td>Submission Type</td>
                    <td>
                      <Select ref={typeRef} defaultValue={subType}>
                        <option value={SUBTYPES.incomplete}>
                          {SUBTYPES.incomplete}
                        </option>
                        <option value={SUBTYPES.physical}>
                          {SUBTYPES.physical}
                        </option>
                        <option value={SUBTYPES.digital}>
                          {SUBTYPES.digital}
                        </option>
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <td>Upload Photos</td>
                    <td>
                      <input
                        type="file"
                        name="images"
                        multiple
                        onChange={handleUpload}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <SmallButton name="cancel" onClick={onClose} color="orange">
                Cancel
              </SmallButton>
              <SmallButton type="submit" name="submit">
                Save
              </SmallButton>
              <SmallButton
                name="delete"
                onClick={handleDelete}
                color="red"
                style={{ float: 'right' }}
              >
                Delete
              </SmallButton>
            </form>
          </Wrapper>
        )
      }}
    </TeamContext.Consumer>
  )
}

const Wrapper = styled.div``

const UploadingMessage = styled.div``

export default EditSubmission
