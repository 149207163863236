import React, { useState, useContext, Component } from 'react'
import styled from 'styled-components'
import TeamContext from './TeamContext'
import EditSubmission from './EditSubmission'
import SubmissionDetails from './SubmissionDetails'
import TrashIcon from './TrashIcon'
import { SmallButton } from './atoms/Buttons'

const getSubType = (type) => {
  if (type === null || type === undefined) return 'Incomplete Submission'
  if (type === false) return 'Digital Submission'
  if (type === true) return 'Physical Submission'
}

const Submission = ({ submission }) => {
  const [open, setOpen] = useState(false)
  const [editing, setEditing] = useState(false)
  const ctx = useContext(TeamContext)
  const subImages = submission.images || []
  const subType = getSubType(submission.isPhysical)

  let createdAt = ''
  if (submission.createdAt > 0) {
    createdAt = new Date(submission.createdAt).toLocaleString()
  }

  const deleteImage = (subId, imageId) => {
    ctx.deleteSubmissionImage(subId, imageId)
  }

  window.submissions = window.submissions || {}
  window.submissions[submission.id] = submission

  return (
    <Wrapper
      className="submission"
      $incomplete={subType === 'Incomplete Submission'}
      $editing={editing}
    >
      {editing ? (
        <EditSubmission
          submission={submission}
          numImages={subImages.length}
          onClose={() => setEditing(false)}
        />
      ) : (
        <SubmissionDetails
          submission={submission}
          numImages={subImages.length}
          onEdit={() => setEditing(true)}
        />
      )}

      {subImages.length > 0 && (
        <SmallButton className="toggle-button" onClick={() => setOpen(!open)}>
          {open ? 'Hide Images' : `Show ${subImages.length} Images`}
        </SmallButton>
      )}
      <Images>
        {open &&
          subImages.map((image, i) => (
            <ImageWrapper key={image.id || i}>
              <a href={image.url} target="_blank" rel="noreferrer">
                <Image src={image.url} />
              </a>
              <TrashIcon
                onClick={() => {
                  const accept = window.confirm(
                    'Are you sure you want to delete this image?'
                  )
                  if (accept) {
                    deleteImage(submission.id, image.id)
                  }
                }}
              />
            </ImageWrapper>
          ))}
      </Images>
    </Wrapper>
  )
}

const ImageWrapper = styled.div`
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  a {
    display: inline-block;
  }
`

const Wrapper = styled.div`
  background-color: ${(p) => (p.incomplete ? '#ffefde' : '#efefef')};
  opacity: ${(p) => (p.incomplete && !p.editing ? '0.70' : '1')};
  padding: 15px;
  margin-bottom: 20px;
`

const Image = styled.img`
  display: inline-block;
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 5px;
  object-fit: contain;
`

const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
`

export default Submission
