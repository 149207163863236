import React, { Component } from 'react'
import apiClient from './apiClient'
import styled from 'styled-components'
import SubmissionsList from './SubmissionsList'
import orderBy from 'lodash/orderBy'
import { Link } from 'react-router-dom'
import TeamContext from './TeamContext'
import { withChallengeContext } from './ChallengeContext'

class DuplicatesScreen extends Component {
  state = { selectedValue: '1' }
  componentDidMount() {
    this.loadDuplicates('1')
  }

  loadDuplicates = (time) => {
    this.setState({ selectedValue: time })
    this.context.fetchTeams({
      dupSubs: true,
      challengeId: this.props.challengeContext.selectedChallenge,
      time: time,
    })
  }

  handleSelectChange = (e) => {
    const value = e.target.value
    this.loadDuplicates(value)
  }

  static contextType = TeamContext

  render() {
    if (this.context.fetching) return <p>Loading....</p>
    const teams = this.context.teams || []
    return (
      <Wrapper>
        <div className="header">
          <h1>Teams with Potential Duplicate Submissions</h1>
          <p>
            Showing teams with submissions within{' '}
            <select
              value={this.state.selectedValue}
              onChange={this.handleSelectChange}
            >
              <option value="1">1 second</option>
              <option value="5">5 seconds</option>
              <option value="15">15 seconds</option>
              <option value="60">60 seconds</option>
              <option value="300">5 minutes</option>
            </select>{' '}
            of each other.
          </p>
          <p>
            There were <b>{teams.length}</b> teams found that met this criteria
          </p>
        </div>
        {teams.map((result, i) => {
          return <SubmissionDuplicate key={i} result={result} />
        })}
      </Wrapper>
    )
  }
}

const SubmissionDuplicate = ({ result }) => {
  const { submissions, team } = result
  const submissionList = Object.keys(submissions).map((id) => submissions[id])

  return (
    <DuplicateWrapper>
      <Link to={`/teams?q=${encodeURI(result.email)}`}>{result.email}</Link>
      <SubmissionsList submissions={orderBy(submissionList, 'createdAt')} />
    </DuplicateWrapper>
  )
}

const Wrapper = styled.div`
  & > .header {
    margin-bottom: 50px;
  }
`

const DuplicateWrapper = styled.div`
  border-bottom: solid 2px black;
  padding-bottom: 50px;
  margin-bottom: 50px;

  a {
    font-size: 22px;
  }
`

export default withChallengeContext(DuplicatesScreen)
