import React from 'react'
import styled from 'styled-components'
import Submission from './Submission'

const Wrapper = styled.div``

const SubmissionsList = ({ submissions }) => {
  return (
    <Wrapper>
      <p>
        <b>Team Submissions {(submissions && submissions.length) || 0}</b>
      </p>
      <div className="submissions">
        {submissions.map((s) => (
          <Submission key={s.id} submission={s} />
        ))}
      </div>
    </Wrapper>
  )
}

export default SubmissionsList
