import React from 'react'
import { SmallButton } from './atoms/Buttons'
import Table from './atoms/Table'
import getSubmissionInfo from './util/getSubmissionInfo'

const Socials = {
  insta: 'Instagram',
  twitter: 'Twitter',
  facebook: 'Facebook',
}

const EditSubmission = ({ submission, numImages, onEdit }) => {
  const {
    createdAt,
    submittedAt,
    value,
    type: subType,
  } = getSubmissionInfo(submission)

  const socials = submission.teamSocials
    ? Object.entries(submission.teamSocials).map((entry) => {
        const [key, value] = entry
        return value ? (
          <tr>
            <td>{Socials[key]}</td>
            <td>{value}</td>
          </tr>
        ) : null
      })
    : null

  return (
    <div>
      <Table>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{submission.id}</td>
          </tr>
          <tr>
            <td>Date Created:</td>
            <td>{createdAt}</td>
          </tr>
          <tr>
            <td>Date Submitted:</td>
            <td>{submittedAt}</td>
          </tr>
          <tr>
            <td>Total Items:</td>
            <td>{submission.itemCount}</td>
          </tr>
          <tr>
            <td>Total Young People</td>
            <td>{submission.youngPeopleCount}</td>
          </tr>
          <tr>
            <td>Total Digital Images</td>
            <td>{numImages}</td>
          </tr>
          <tr>
            <td>Submission Type</td>
            <td>{subType}</td>
          </tr>
          <tr>
            <td>Submission Value</td>
            <td>
              {value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </td>
          </tr>
          {socials}
        </tbody>
      </Table>
      <SmallButton onClick={onEdit}>Edit</SmallButton>
    </div>
  )
}

export default EditSubmission
