import React from 'react'
import styled from 'styled-components'

export const TextInput = styled.input`
  font-size: 16px;
  width: 100%;
`

export const Select = styled.select`
  font-size: 16px;
  width: 100%;
  padding: 5px;
`
