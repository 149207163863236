import React from 'react'
import styled from 'styled-components'

const TrashIcon = ({ onClick }) => {
  return (
    <Icon onClick={onClick}>
      <svg
        className="icon-trash"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <path
          className="st2"
          d="M23 77V31h54v46M21 23h58M59 23c0-5-4-9-9-9s-9 4-9 9M23 77c0 5 4 9 9 9h36c5 0 9-4 9-9M41 31v55M59 31v55"
        />
      </svg>
    </Icon>
  )
}

export default TrashIcon

const Icon = styled.div`
  .icon-trash {
    fill: none;
    stroke: black;
    stroke-width: 4;
    stroke-miterlimit: 10;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    &:hover {
      stroke: red;
    }
  }
`
