import React from 'react'
import styled from 'styled-components'

export const Button = styled.button`
  width: 150px;
  height: 60px;
  background-color: none;
  border: none;
  font-size: 20px;
  border: solid 2px blue;
  border-radius: 3px;
  border-color: ${(props) => props.color || 'blue'};
  color: ${(props) => props.color || 'blue'};
  transition: all 120ms ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.color || 'blue'};
    color: white;
  }
`

export const SmallButton = styled.button`
  width: 130px;
  height: 45px;
  background-color: none;
  border: none;
  font-size: 12px;
  border: solid 2px blue;
  border-radius: 3px;
  border-color: ${(props) => props.color || 'blue'};
  color: ${(props) => props.color || 'blue'};
  transition: all 120ms ease-in-out;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 10px;

  &:hover {
    background-color: ${(props) => props.color || 'blue'};
    color: white;
  }
`

export const PageLinkButton = styled(Button)`
  font-size: 16px;
  width: 200px;
  height: 50px;
  margin-right: 20px;
  ${(props) => {
    if (props.$active) {
      return {
        backgroundColor: 'blue',
        color: 'white',
      }
    }
  }};
`

export const ButtonGroup = styled.div`
  display: block;
  ${Button} {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`
