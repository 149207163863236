import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import apiClient from './apiClient'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import getSubmissionInfo from './util/getSubmissionInfo'

class RecentSubmissionsScreen extends Component {
  state = {
    submissions: [],
    params: {
      offset: 0,
    },
  }

  fetchSubs = debounce(async () => {
    this.setState({
      fetching: true,
      error: undefined,
    })

    const submissions = (
      await apiClient.getRecentSubmissions(this.state.params)
    ).filter((s) => {
      return s.submittedAt
    })

    this.setState({
      submissions: submissions,
      fetching: false,
      error: undefined,
    })
  }, 500)

  componentDidMount() {
    this.fetchSubs()
  }

  render() {
    return (
      <div>
        <h1>Recent Submissions</h1>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Submitted Date</th>
              <th>Items</th>
              <th>People</th>
              <th>Type</th>
              <th>Name</th>
              <th>Email</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {this.state.submissions.map((s) => {
              const { submittedAt, value, type } = getSubmissionInfo(s)

              const rowClass = type.match(/incomplete/i) ? 'incomplete' : ''
              return (
                <tr key={s.id} className={rowClass}>
                  <td>
                    {s.id.slice(0, 7)}
                    ...
                  </td>
                  <td>{submittedAt}</td>
                  <td>{s.itemCount}</td>
                  <td>{s.youngPeopleCount}</td>
                  <td>{type}</td>
                  <td>{s.team_data.name}</td>
                  <td>
                    <Link to={`/teams?q=${encodeURI(s.team_data.email)}`}>
                      {s.team_data.email}
                    </Link>
                  </td>
                  <td>
                    {value.toLocaleString('en-us', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

const Table = styled.table`
  border-collapse: collapse;
  text-align: left;
  width: 100%;

  td,
  th {
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px gray;
    border-top: solid 1px gray;
  }

  .incomplete td {
    background-color: #ffefde;
    opacity: 0.7;
  }
`

export default RecentSubmissionsScreen
