import React from 'react'

const ChallengeContext = React.createContext({})

export class ChallengeContextProvider extends React.Component {
  state = {
    selectedChallenge:
      process.env.REACT_APP_DEFAULT_CHALLENGE_ID || '7012I000001q7UzQAI',
  }

  setChallenge = (id) => {
    this.setState({
      selectedChallenge: id,
    })
  }

  render() {
    return (
      <ChallengeContext.Provider
        value={{
          selectedChallenge: this.state.selectedChallenge,
          setChallenge: this.setChallenge,
        }}
      >
        <div key={this.state.selectedChallenge}>{this.props.children}</div>
      </ChallengeContext.Provider>
    )
  }
}

export const withChallengeContext = (Component) => {
  return (props) => {
    return (
      <ChallengeContext.Consumer>
        {(challengeContext) => (
          <Component {...props} challengeContext={challengeContext} />
        )}
      </ChallengeContext.Consumer>
    )
  }
}

export default ChallengeContext
