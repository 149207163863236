import React, { Component } from 'react'
import Team from './Team'
import Pager from './Pager'
import styled from 'styled-components'
import queryString from 'query-string'
import TeamContext from './TeamContext'
import FilterSection from './atoms/FilterSection'
import { withChallengeContext } from './ChallengeContext'
import ParamControl from './atoms/ParamControl'

const updateSubmission = (id, result) => (state, props) => {
  console.log('updating submissions', { id, result, state, props })
  return null
}

const updateParam = (name, value) => (state, props) => {
  const params = { ...state.params }
  params[name] = value
  return {
    params,
  }
}

class Teams extends Component {
  state = {
    params: {
      searchTerm: '',
      registered: undefined,
      offset: 0,
    },
  }

  static contextType = TeamContext

  getSearchParams = () => {
    return {
      ...this.state.params,
      challengeId: this.props.challengeContext.selectedChallenge,
    }
  }

  // fetchTeams = debounce(async () => {
  //   const searchTerm = this.getSearchParams().searchTerm;
  //   // if (!searchTerm || searchTerm.length <= 0) {
  //   //   return;
  //   // }

  //   this.setState({
  //     fetching: true,
  //     error: undefined
  //   });

  //   const teams = await apiClient.getTeams(this.getSearchParams());

  //   let totalTeams = 0;
  //   if (teams && teams[0]) {
  //     totalTeams = teams[0].full_count;
  //   }

  //   this.setState({
  //     teams: teams,
  //     fetching: false,
  //     error: undefined,
  //     totalTeams: totalTeams
  //   });
  // }, 500);

  fetchTeams = () => {
    this.context.fetchTeams(this.getSearchParams())
  }

  handleSearchChange = (e) => {
    this.setState(updateParam('searchTerm', e.target.value), this.fetchTeams)
  }

  handlePageChange = (data) => {
    this.setState(updateParam('offset', data.selected * 10), this.fetchTeams)
  }

  handleFilterChange = (data) => {
    console.log('handling filter change: ', data)
    this.setState(updateParam('registered', data.registered), this.fetchTeams)
  }

  setParam = (name, value) => {
    console.log('set param: ', name, value)
    this.setState(updateParam(name, value), this.fetchTeams)
  }

  handleSubmissionUpdate = (id, result) => {
    this.setState(updateSubmission(id, result))
  }

  componentDidMount() {
    console.log('fetching team stats from screen ')
    this.context.fetchTeamStats(this.getSearchParams())
    const query = queryString.parse(this.props.location?.search)
    if (query.q) {
      this.setState(updateParam('searchTerm', query.q), this.fetchTeams)
    } else {
      this.fetchTeams()
    }
  }

  render() {
    const teams = this.context.teams || []
    let totalTeams = 0
    let totalPages = 0
    if (teams.length > 0) {
      totalTeams = teams[0].full_count
      totalPages = Math.ceil(totalTeams / 10)
    }

    const paramState = {
      current: this.state.params,
      setParam: this.setParam,
    }

    console.log('stats: ', this.context)
    return (
      <div>
        <h1>Teams</h1>
        <p>
          <b>
            Total Registered Teams: {this.context.teamStats.registered_teams}
          </b>
        </p>
        <p>
          <b>
            Incomplete Registrations:{' '}
            {this.context.teamStats.incomplete_registrations}
          </b>
        </p>
        <FilterSection>
          <ParamControl {...paramState} param="registered" value={undefined}>
            All Teams
          </ParamControl>
          <ParamControl {...paramState} param="registered" value={true}>
            Registered Only
          </ParamControl>
          <ParamControl {...paramState} param="registered" value={false}>
            Unregistered Only
          </ParamControl>
        </FilterSection>
        <Input
          value={this.getSearchParams().searchTerm}
          onChange={this.handleSearchChange}
        />
        <Pager
          key={totalPages}
          pageCount={isNaN(totalPages) ? 1 : totalPages}
          totalResults={totalTeams}
          onPageChange={this.handlePageChange}
          initialPage={0}
        />
        {this.context.teams.map((team) => (
          <Team key={team.id} team={team} />
        ))}
      </div>
    )
  }
}

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 20px;
`

export default withChallengeContext(Teams)
