import React, { Component } from 'react'
import Team from './Team'
import Pager from './Pager'
import TeamContext from './TeamContext'
import { withChallengeContext } from './ChallengeContext'

const updateSubmission = (id, result) => (state, props) => {
  return null
}

const updateParam = (name, value) => (state, props) => {
  const params = { ...state.params }
  params[name] = value
  return {
    params,
  }
}

class Teams extends Component {
  state = {
    params: {
      incompleteSubs: true,
      offset: 0,
    },
  }

  static contextType = TeamContext

  fetchTeams = () => {
    console.log('fetching: ', this.state.params)
    this.context.fetchTeams({
      ...this.state.params,
      challengeId: this.props.challengeContext.selectedChallenge,
    })
  }

  handleSearchChange = (e) => {
    this.setState(updateParam('searchTerm', e.target.value), this.fetchTeams)
  }

  handlePageChange = (data) => {
    this.setState(updateParam('offset', data.selected * 10), this.fetchTeams)
  }

  handleSubmissionUpdate = (id, result) => {
    this.setState(updateSubmission(id, result))
  }

  componentDidMount() {
    this.context.fetchTeams(this.state)
  }

  render() {
    const teams = this.context.teams || []
    let totalTeams = 0
    let totalPages = 0
    if (teams.length > 0) {
      totalTeams = teams[0].full_count
      totalPages = Math.ceil(totalTeams / 10)
    }

    return (
      <div>
        <h1>Teams with Incomplete Submissions</h1>
        <Pager
          key={totalPages}
          pageCount={isNaN(totalPages) ? 1 : totalPages}
          totalResults={totalTeams}
          onPageChange={this.handlePageChange}
          initialPage={0}
        />
        {this.context.teams.map((team) => (
          <Team key={team.id} team={team} />
        ))}
      </div>
    )
  }
}

export default withChallengeContext(Teams)
