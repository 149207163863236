// TODO: don't repeat this code on sr-web, find better
// way to have a source of truth here.
// it can be found in computeDollarsRaised.js on sr-web.

export default (submission) => {
  // Pacific Time dates
  // See https://en.wikipedia.org/wiki/Daylight_saving_time_in_the_United_States for duration of DST
  const START = 'T00:00:00'
  const END = 'T23:59:59'
  const PST = '-08:00'
  const PDT = '-07:00'

  const { isPhysical, submittedAt, itemCount } = submission

  if (isPhysical === undefined) return 0
  if (submittedAt === undefined) return 0

  const submissionDate = submittedAt

  let defaultAmount = 5
  let incentiveAmount = 10
  let incentiveStartDate = new Date('May 01 2022 07:00:00 UTC')
  let incentiveEndDate = new Date('Jun 04 2022 07:00:00 UTC')

  // various cases here are necessary to properly display
  // dollars raised during various challenges

  // all prev challenges
  if (submissionDate <= new Date('2019-7-1')) {
    incentiveStartDate = new Date('Mar 22 2019 07:00:00 UTC')
    incentiveEndDate = new Date('Apr 23 2019 07:00:00 UTC')
    defaultAmount = 2
    incentiveAmount = 4
  }

  // hunger challenge
  if (
    submissionDate >= new Date('2019-09-17') &&
    submissionDate <= new Date('2020-06-30')
  ) {
    incentiveStartDate = new Date('Mar 01 2020 07:00:00 UTC')
    incentiveEndDate = new Date('Jun 26 2020 07:00:00 UTC')
    defaultAmount = 3
    incentiveAmount = 6
  }

  // changemaker challenge
  if (
    submissionDate >= new Date('2020-08-23') &&
    submissionDate <= new Date('2021-06-05')
  ) {
    incentiveStartDate = new Date('Mar 01 2021 07:00:00 UTC')
    incentiveEndDate = new Date('Apr 30 2021 07:00:00 UTC')
    defaultAmount = 5
    incentiveAmount = 10
  }

  // world needs challenge pt.1
  if (
    submissionDate >= new Date('2021-09-14') &&
    submissionDate <= new Date('2022-04-02')
  ) {
    incentiveStartDate = new Date('Mar 01 2022 07:00:00 UTC')
    incentiveEndDate = new Date('Mar 31 2022 07:00:00 UTC')
    defaultAmount = 5
    incentiveAmount = 10
  }

  // world needs challenge pt.2
  if (
    submissionDate >= new Date('2022-04-02') &&
    submissionDate <= new Date('2022-06-04')
  ) {
    incentiveStartDate = new Date('May 01 2022 07:00:00 UTC')
    incentiveEndDate = new Date('Jun 04 2022 07:00:00 UTC')
    defaultAmount = 5
    incentiveAmount = 10
  }

  // welcoming refugees challenge
  if (
    submissionDate >= new Date('2022-09-12'.concat(START, PDT)) &&
    submissionDate <= new Date('2023-06-23'.concat(END, PDT))
  ) {
    const doubleDays = [
      {
        // PST (UTC-08:00) starts on Nov 6 2022
        start: new Date('2022-11-16'.concat(START, PST)),
        end: new Date('2022-11-21'.concat(END, PST)),
      },
      {
        start: new Date('2022-12-16'.concat(START, PST)),
        end: new Date('2022-12-21'.concat(END, PST)),
      },
      {
        start: new Date('2023-01-22'.concat(START, PST)),
        end: new Date('2023-01-26'.concat(END, PST)),
      },
      {
        start: new Date('2023-02-01'.concat(START, PST)),
        end: new Date('2023-02-28'.concat(END, PST)),
      },
      {
        // PDT (UTC-07:00) starts on Mar 12 2023
        start: new Date('2023-03-18'.concat(START, PDT)),
        end: new Date('2023-03-22'.concat(END, PDT)),
      },
      {
        start: new Date('2023-04-18'.concat(START, PDT)),
        end: new Date('2023-04-22'.concat(END, PDT)),
      },
      {
        start: new Date('2023-05-01'.concat(START, PDT)),
        end: new Date('2023-05-31'.concat(END, PDT)),
      },
      {
        start: new Date('2023-06-18'.concat(START, PDT)),
        end: new Date('2023-06-22'.concat(END, PDT)),
      },
    ]

    const tripleDays = [
      {
        start: new Date('2023-02-18'.concat(START, PST)),
        end: new Date('2023-02-22'.concat(END, PST)),
      },
      {
        start: new Date('2023-05-14'.concat(START, PDT)),
        end: new Date('2023-05-18'.concat(END, PDT)),
      },
    ]

    defaultAmount = 5
    incentiveAmount = 10

    for (const range of doubleDays) {
      if (submissionDate <= range.end) {
        incentiveStartDate = range.start
        incentiveEndDate = range.end
        break
      }
    }

    for (const range of tripleDays) {
      if (submissionDate <= range.end && submissionDate >= range.start) {
        incentiveStartDate = range.start
        incentiveEndDate = range.end
        incentiveAmount = 15
        break
      }
    }
  }

  // 2024 Extraordinary Earth Project
  if (
    submissionDate >= new Date('2023-08-16'.concat(START, PDT)) &&
    submissionDate <= new Date('2024-06-23'.concat(END, PDT))
  ) {
    const doubleDays = [
      {
        // September 21st Arbor Day
        // 9/19-25 Recycle Now week
        start: new Date('2023-09-18'.concat(START, PDT)),
        end: new Date('2023-09-22'.concat(END, PDT)),
      },
      {
        // Oct. 22 World Planting Day
        // Oct. 24 International Day of Climate Action
        start: new Date('2023-10-20'.concat(START, PDT)),
        end: new Date('2023-10-24'.concat(END, PDT)),
      },
      {
        // PST (UTC-08:00) starts on Nov 6 2023
        // Nov 15 National Recycling Day
        // Nov 17 National Hiking Day
        start: new Date('2023-11-14'.concat(START, PST)),
        end: new Date('2023-11-18'.concat(END, PST)),
      },
      {
        // Dec 11: Int. Mountain Day (UN)
        // Dec 14 National Energy Conservation Day
        start: new Date('2023-12-11'.concat(START, PST)),
        end: new Date('2023-12-15'.concat(END, PST)),
      },
      {
        // January 9 National Electricity Day
        start: new Date('2024-01-08'.concat(START, PST)),
        end: new Date('2024-01-10'.concat(END, PST)),
      },
      {
        // February is a Doubles Month in a Leap Year
        start: new Date('2024-02-01'.concat(START, PST)),
        end: new Date('2024-02-29'.concat(END, PST)),
      },
      {
        // PDT (UTC-07:00) starts on Mar 10 2024
        // 3/18 Global Recycling Day
        // 3/21: Int. Day of Forests & Nat’l Renewable Energy Day and World Planting Day
        // 3/22: World Water Day
        // 3/23: Earth Hour (World Wildlife Fund)
        start: new Date('2024-03-18'.concat(START, PDT)),
        end: new Date('2024-03-24'.concat(END, PDT)),
      },
      {
        // April is a Doubles Month
        start: new Date('2024-04-01'.concat(START, PDT)),
        end: new Date('2024-04-30'.concat(END, PDT)),
      },
      {
        // May 17 Endangered Species Day
        // May 20 World Bee Day
        // May 22 World Biodiversity Day
        start: new Date('2024-05-17'.concat(START, PDT)),
        end: new Date('2024-05-22'.concat(END, PDT)),
      },
      {
        // June 5 World Environment Day
        // June 8: World Oceans Day
        start: new Date('2024-06-05'.concat(START, PDT)),
        end: new Date('2024-06-10'.concat(END, PDT)),
      },
    ]
    // Noted in the doc, but after the challenge ends:
    // July 26th International Day for the Conservation of the Mangrove Ecosystems
    // July 28th World Nature Conservation Day

    const tripleDays = [
      {
        // February 2nd World Wetlands Day
        start: new Date('2024-02-02'.concat(START, PST)),
        end: new Date('2024-02-04'.concat(END, PST)),
      },
      {
        // April 22nd Earth Day
        start: new Date('2024-04-20'.concat(START, PDT)),
        end: new Date('2024-04-24'.concat(END, PDT)),
      },
    ]

    defaultAmount = 5
    incentiveAmount = 10

    for (const range of doubleDays) {
      if (submissionDate <= range.end) {
        incentiveStartDate = range.start
        incentiveEndDate = range.end
        break
      }
    }

    for (const range of tripleDays) {
      if (submissionDate <= range.end && submissionDate >= range.start) {
        incentiveStartDate = range.start
        incentiveEndDate = range.end
        incentiveAmount = 15
        break
      }
    }
  }

  if (
    submissionDate >= incentiveStartDate &&
    submissionDate <= incentiveEndDate
  ) {
    return incentiveAmount * itemCount
  } else {
    return defaultAmount * itemCount
  }
}
