import React from 'react'
import styled from 'styled-components'

export default styled.table`
  border-collapse: collapse;

  & > tbody > tr > td:first-child {
    padding-right: 50px;
    font-weight: bold;
  }

  td {
    height: 30px;
  }
`
