import axios from 'axios'

// const API_URL = "http://localhost:3002";
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002'
const API_TOKEN = 'Kc6A68Ltyg743UCVMk4y0vMxr'

export class ApiClient {
  constructor(props) {
    this.client = axios.create({
      baseURL: API_URL,
      headers: {
        Authorization: `Admin ${API_TOKEN}`,
      },
    })
  }

  async getChallenges() {
    const response = await this.client.get('/challenges')
    return response.data
  }

  async getImages(params) {
    console.log('fetching: ', `/approvals`, params)
    const response = await this.client.post(`/approvals`, params)
    return response.data
  }

  async getTeamStats(params) {
    console.log('getting team stats: ', params)
    const response = await this.client.post(`/admin/team-stats`, params)
    return response.data ? response.data[0] : {}
  }

  async getTeam(params) {
    console.log('api get teams called: ', params)
    const path = `/admin/teams/${params.id}`
    console.log('fetching: ', path, params)
    const response = await this.client.get(path, params)
    return response.data
  }

  async getSubmission(params) {
    console.log('api get submission called: ', params)
    const path = `/admin/submissions/${params}`
    console.log('fetching: ', path, params)
    const response = await this.client.get(path, params)
    return response.data
  }

  async updateTeam(id, params) {
    console.log('updating: ', `/admin/teams/`, id)
    const response = await this.client.post(`/admin/teams/${id}`, params)
    return response.data
  }

  async getTeams(params) {
    console.log('api get teams called: ', params)
    if (params.incompleteSubs) {
      console.log('fetching: ', `/admin/incomplete-submissions`, params)
      const response = await this.client.post(
        `/admin/incomplete-submissions`,
        params
      )
      return response.data
    } else if (params.dupSubs) {
      const response = await this.client.get(
        `/admin/duplicate-submissions/${params.time}`,
        { params: { challengeId: params.challengeId } }
      )
      return response.data
    } else {
      console.log('fetching: ', `/admin/teams`, params)
      const response = await this.client.post(`/admin/teams`, params)
      return response.data
    }
  }

  async getRecentSubmissions(params) {
    console.log('fetching: ', `/admin/submissions`, params)
    const response = await this.client.get(`/admin/submissions`, params)
    return response.data
  }

  async updateSubmission(id, params) {
    console.log('updating: ', `/admin/submissions`, id)
    const response = await this.client.post(`/admin/submissions/${id}`, params)
    return response.data
  }

  async deleteSubmission(id) {
    console.log('deleting: ', `/admin/submissions`, id)
    const response = await this.client.delete(`/admin/submissions/${id}`)
    return response.data
  }

  async deleteTeam(id) {
    console.log('deleting: ', `/admin/teams`, id)
    const response = await this.client.delete(`/admin/teams/${id}`)
    return response.data
  }

  async deleteSubmissionImage(id) {
    console.log('deleting: ', `/admin/images`, id)
    const response = await this.client.delete(`/admin/images/${id}`)
    return response.data
  }

  async createSubmission(teamId) {
    const response = await this.client.post(`/admin/submissions`, { teamId })
    return response.data
  }

  async getSigningRequest(submissionId) {
    const response = await this.client.get(
      `/admin/submissions/${submissionId}/sign`,
      {
        params: {
          fileName: 'somefile.jpg',
          contentType: 'image/jpeg',
        },
      }
    )
    return response.data
  }

  async uploadFileToSubmission(submissionId, file) {
    console.log('uploading: ', submissionId, file)
    const sign = await this.getSigningRequest(submissionId, {
      fileName: file.name,
      contentType: file.type,
    })
    console.log('got ')

    const response = await fetch(sign.signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    })

    const result = await this.client.post(
      `/admin/submissions/${submissionId}/images`,
      {
        urls: [sign.publicUrl],
      }
    )
    return result.data && result.data[0]
  }

  async sendAuthEmail({ email, type, origin, firstName }) {
    console.log('sending auth email: ', { email, type, origin, firstName })
    const response = await this.client.post(`/auth`, {
      email,
      type,
      origin,
      firstName,
    })
    return response.data
  }

  async getAdminAuthToken({ email }) {
    const response = await this.client.post(`/admin/generateAuthToken`, {
      email,
    })
    return response.data
  }

  async approveImage(id) {
    console.log('approving image', id)
    const response = await this.client.post(`/approvals/approve/${id}`)
    return response.status === 200
  }

  async revertImage(id) {
    console.log('reverting image', id)
    const response = await this.client.post(`/approvals/revert/${id}`)
    return response.status === 200
  }

  async rejectImage(id, params) {
    console.log('rejecting image', id)
    const response = await this.client.post(`/approvals/reject/${id}`, params)
    return response.status === 200
  }
}

export default new ApiClient()
