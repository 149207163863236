import React, { Component } from 'react'
import styled from 'styled-components'
import TeamDetails from './TeamDetails'
import SubmissionsList from './SubmissionsList'
import { SmallButton } from './atoms/Buttons'
import TeamContext from './TeamContext'
import Spinner from './atoms/Spinner'

const Wrapper = styled.div`
  border: solid 1px gray;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > *:first-child {
      flex: 1 1 60%;
      max-width: 60%;
      margin-right: 30px;
    }

    > *:last-child {
      flex: 1 1 40%;
      max-width: 40%;
    }
  }
`

class Team extends Component {
  state = { open: false, loading: false }

  static contextType = TeamContext

  handleNewSubmissions = () => {
    this.context.createSubmission(this.props.team.id)
  }

  handleTeamDelete = async (teamId) => {
    this.setState({ loading: true })
    await this.context.deleteTeam(teamId)
    this.setState({ loading: false })
  }

  render() {
    const { team, updateSubmission } = this.props
    const teamPhoto = team.teamImages.find((i) => (i.id = team.teamImageId))
    const submissions = team.submissions || []

    if (this.state.loading)
      return (
        <Wrapper>
          <Spinner />
        </Wrapper>
      )

    return (
      <Wrapper>
        <div className="header">
          <TeamDetails team={team} onDelete={this.handleTeamDelete} />
          <TeamPhoto teamPhoto={teamPhoto} />
        </div>
        <div className="submissions">
          <h2>Team Submissions ({submissions.length})</h2>
          <SmallButton onClick={this.handleNewSubmissions} color="blue">
            New Submission
          </SmallButton>
          <SubmissionsList submissions={submissions} />
        </div>
      </Wrapper>
    )
  }
}

const TeamPhotoWrapper = styled.div`
  img {
    max-width: 100%;
    max-height: 300;
    object-fit: contain;
    border: solid 3px gray;
  }
`

const TeamPhoto = ({ teamPhoto }) => {
  return (
    <TeamPhotoWrapper>
      {teamPhoto && teamPhoto.url && <img src={teamPhoto.url} />}
    </TeamPhotoWrapper>
  )
}

export default Team
