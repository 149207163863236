import { PageLinkButton } from './Buttons'
import styled from 'styled-components'

const FilterSection = styled.div`
  margin-bottom: 2em;

  ${PageLinkButton} {
    margin-bottom: 1em;
  }
`

export default FilterSection
