import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import getTeamDollarsRaised from './util/getTeamDollarsRaised'
import { SmallButton } from './atoms/Buttons'
import apiClient from './apiClient'
import get from 'lodash/get'

const Wrapper = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  table,
  td {
    border: solid 1px gray;
  }

  tr {
    td:first-child {
      font-weight: bold;
      background-color: #efefef;
    }
  }
  td {
    padding: 10px;
  }
`

const TeamDetails = ({ team, onDelete }) => {
  const [loginToken, setLoginToken] = useState('')
  const teamName = team.name || 'n/a'

  const completedSubmissions = team.submissions.filter((s) => {
    return s.isPhysical !== undefined
  })
  const totalItems = completedSubmissions.reduce((total, sub) => {
    total = total + sub.itemCount
    return total
  }, 0)

  const totalPeople = completedSubmissions.reduce((total, sub) => {
    total = total + sub.youngPeopleCount
    return total
  }, 0)

  const origin = window.location.origin.match(/localhost:3/)
    ? 'http://localhost:3000'
    : 'https://www.studentsrebuild.org'

  const handleSendAuthClick = async () => {
    let firstName = get(team, 'contact.firstName')
    const confirm = window.confirm(
      `Are you sure you wish to send a new authorization email to ${team.email}?`
    )

    let origin = window.location.origin

    if (confirm) {
      try {
        const result = await apiClient.sendAuthEmail({
          email: team.email,
          firstName,
          origin: origin,
        })
        if (!result) {
          window.alert(
            'Email Send Request Failed. Please Contact Fuzzco Technical Support'
          )
        } else {
          window.alert(
            `Auth Email Request for ${team.email} Sent to Salesforce Successfully`
          )
        }
      } catch (error) {
        window.alert(
          'Email Send Request Failed. Please Contact Fuzzco Technical Support'
        )
      }
    }
  }

  const handleLoginLinkClick = async () => {
    const result = await apiClient.getAdminAuthToken({ email: team.email })
    setLoginToken(result.token)
  }

  const handleDeleteClick = async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete team ${teamName} for ${team.email}?`
    )
    if (confirmed) {
      await onDelete(team.id)
    }
  }

  const loginUrl = `${origin}/login/${loginToken}`
  const teamUrl = `https://www.studentsrebuild.org/teams/${team.id}`

  return (
    <Wrapper>
      <table>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{teamName}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{team.email}</td>
          </tr>
          <tr>
            <td>Contact Name</td>
            <td>
              {team.contact.firstName} {team.contact.lastName}
            </td>
          </tr>
          <tr>
            <td>Registered</td>
            <td>{team.registered ? 'True' : 'False'}</td>
          </tr>
          <tr>
            <td>Organization Name</td>
            <td>{(team.organization && team.organization.name) || 'n/a'}</td>
          </tr>
          <tr>
            <td>Contact Age</td>
            <td>{team.contact.age}</td>
          </tr>
          {team.location && (
            <tr>
              <td>Location</td>
              <td>
                {team.location.city + ',' || ''} {team.location.state || ''},{' '}
                {team.location.country || ''}
              </td>
            </tr>
          )}
          <tr>
            <td>Team ID</td>
            <td>{team.id}</td>
          </tr>
          <tr>
            <td>Total items</td>
            <td>{totalItems}</td>
          </tr>
          <tr>
            <td>Total People</td>
            <td>{totalPeople}</td>
          </tr>
          <tr>
            <td>Dollars Raised</td>
            <td>
              {getTeamDollarsRaised(team).toLocaleString('en-us', {
                style: 'currency',
                currency: 'USD',
              })}
            </td>
          </tr>
          <tr>
            <td>Team Page</td>
            <td>
              <a href={teamUrl} target="_blank" rel="noreferrer">
                {teamUrl}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <Link to={`/teams/${team.id}`}>
        <SmallButton color="blue">Edit team</SmallButton>
      </Link>
      <br />
      <SmallButton onClick={handleSendAuthClick} color="orange">
        Send Login Email
      </SmallButton>
      <br />
      <SmallButton onClick={handleLoginLinkClick} color="orange">
        Generate Login Link
      </SmallButton>
      {onDelete && <br />}
      {onDelete && (
        <SmallButton onClick={handleDeleteClick} color="red">
          Delete Team
        </SmallButton>
      )}
      {loginToken && (
        <p style={{ maxWidth: 400, wordWrap: 'break-word' }}>{loginUrl}</p>
      )}
    </Wrapper>
  )
}

export default TeamDetails
