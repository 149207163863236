import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ApiClient from './apiClient'
import TeamForm from './TeamForm'
import { Title } from './atoms/Text'

const TeamEditScreen = () => {
  const [team, setTeam] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const { id: teamId } = useParams()
  useEffect(() => {
    ApiClient.getTeam({ id: teamId })
      .then((team) => setTeam(team))
      .catch((reason) => setError(JSON.stringify(reason)))
      .finally(() => setLoading(false))
  }, [teamId])

  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    return <div>Loading team error: {this.state.error}</div>
  }

  return (
    <div>
      <Title>Edit Team: {team.name}</Title>
      <TeamForm team={team}></TeamForm>
    </div>
  )
}

export default TeamEditScreen
