import React from 'react'
import apiClient from './apiClient'
import debounce from 'lodash/debounce'
import update from 'immutability-helper'
import findIndex from 'lodash/findIndex'
import ChallengeContext from './ChallengeContext'

const TeamContext = React.createContext({})

export const TeamContextConsumer = TeamContext.Consumer

const lookupTeamBySub = (state, subId) => {
  const team = state.teams.find((t) => {
    const subIds = t.submissions.map((s) => s.id)
    return subIds.includes(subId)
  })

  const teamIndex = findIndex(state.teams, (t) => t.id === team.id)
  const subIndex = findIndex(team.submissions, (s) => s.id === subId)

  return {
    teamIndex,
    subIndex,
    team,
  }
}

const deleteSubmissionState = (id) => (state) => {
  const { teamIndex, subIndex } = lookupTeamBySub(state, id)

  return update(state, {
    teams: {
      [teamIndex]: {
        submissions: {
          $splice: [[subIndex, 1]],
        },
      },
    },
  })
}

const deleteTeamState = (id) => (state) => {
  const teamIndex = findIndex(state.teams, (t) => t.id === id)
  return update(state, {
    teams: {
      $splice: [[teamIndex, 1]],
    },
  })
}

const addSubmissionToTeam = (teamId, submission) => (state) => {
  const teamIndex = findIndex(state.teams, (t) => t.id === teamId)

  return update(state, {
    teams: {
      [teamIndex]: {
        submissions: {
          $unshift: [submission],
        },
      },
    },
  })
}

const addSubmissionImage = (subId, image) => (state) => {
  const { teamIndex, subIndex } = lookupTeamBySub(state, subId)
  if (
    state.teams[teamIndex].submissions[subIndex].images &&
    state.teams[teamIndex].submissions[subIndex].images.length > 0
  ) {
    return update(state, {
      teams: {
        [teamIndex]: {
          submissions: {
            [subIndex]: {
              images: {
                $unshift: [image],
              },
            },
          },
        },
      },
    })
  } else {
    return update(state, {
      teams: {
        [teamIndex]: {
          submissions: {
            [subIndex]: {
              images: {
                $set: [image],
              },
            },
          },
        },
      },
    })
  }
}

const deleteSubmissionImage = (subId, imageId) => (state) => {
  const { teamIndex, subIndex } = lookupTeamBySub(state, subId)
  const imageIndex = findIndex(
    state.teams[teamIndex].submissions[subIndex].images,
    (img) => img.id === imageId
  )
  return update(state, {
    teams: {
      [teamIndex]: {
        submissions: {
          [subIndex]: {
            images: {
              $splice: [[imageIndex, 1]],
            },
          },
        },
      },
    },
  })
}

const mergeSubmissionState = (id, submission) => (state) => {
  const { teamIndex, subIndex } = lookupTeamBySub(state, id)

  if (!('isPhysical' in submission)) {
    submission.isPhysical = undefined
  }
  if (!('submittedAt' in submission)) {
    submission.submittedAt = undefined
  }

  return update(state, {
    teams: {
      [teamIndex]: {
        submissions: {
          [subIndex]: {
            $merge: submission,
          },
        },
      },
    },
  })
}

export class TeamContextProvider extends React.Component {
  state = {
    teams: [],
    teamStats: {},
    fetching: true,
    error: undefined,
    totalTeams: 0,
  }

  static contextType = ChallengeContext

  fetchTeams = debounce(async (params) => {
    console.log('fetching teams: ', params)
    const { searchTerm, incompleteSubs, dupSubs, registered } = params

    // if (
    //   !incompleteSubs &&
    //   !dupSubs //&&
    //   // (!searchTerm || searchTerm.length <= 0)
    // ) {
    //   return;
    // }

    this.setState({
      fetching: true,
      error: undefined,
    })

    console.log('fetching teams: ', params)
    const teams = await apiClient.getTeams(params)

    let totalTeams = 0
    if (teams && teams[0]) {
      totalTeams = teams[0].full_count
    }

    this.setState({
      teams: teams,
      fetching: false,
      error: undefined,
      totalTeams: totalTeams,
    })
  }, 250)

  fetchTeamStats = async (params) => {
    console.log('fetching stats: ', params)
    const result = await apiClient.getTeamStats(params)
    console.log('got team stats: ', result)
    this.setState({
      teamStats: result,
    })
  }

  updateSubmission = async (id, params) => {
    const result = await apiClient.updateSubmission(id, params)
    this.setState(mergeSubmissionState(id, result))
  }

  addSubmissionImage = async (id, file) => {
    const result = await apiClient.uploadFileToSubmission(id, file)
    this.setState(addSubmissionImage(id, result))
  }

  deleteSubmission = async (id) => {
    await apiClient.deleteSubmission(id)
    this.setState(deleteSubmissionState(id))
  }

  deleteTeam = async (id) => {
    await apiClient.deleteTeam(id)
    this.setState(deleteTeamState(id))
  }

  deleteSubmissionImage = async (subId, imageId) => {
    await apiClient.deleteSubmissionImage(imageId)
    this.setState(deleteSubmissionImage(subId, imageId))
  }

  createSubmission = async (teamId) => {
    const result = await apiClient.createSubmission(teamId)
    this.setState(addSubmissionToTeam(teamId, result))
  }

  render() {
    return (
      <TeamContext.Provider
        value={{
          teams: this.state.teams,
          teamStats: this.state.teamStats,
          fetchTeams: this.fetchTeams,
          updateSubmission: this.updateSubmission,
          deleteSubmission: this.deleteSubmission,
          deleteTeam: this.deleteTeam,
          createSubmission: this.createSubmission,
          addSubmissionImage: this.addSubmissionImage,
          deleteSubmissionImage: this.deleteSubmissionImage,
          fetchTeamStats: this.fetchTeamStats,
        }}
      >
        {this.props.children}
      </TeamContext.Provider>
    )
  }
}

export default TeamContext
