import React from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'
import * as Yup from 'yup'

import { Button } from './atoms/Buttons'
import { TextInput } from './atoms/Input'
import ApiClient from './apiClient'

const Form = styled.form`
  display: grid;
  grid-gap: 2rem;
  max-width: 30rem;
`

const Field = styled.div`
  display: grid;
  grid-gap: 0.5rem;
`

const Error = styled.div`
  color: red;
`

const validationSchema = Yup.object({
  name: Yup.string()
    .max(80, 'Name must be 80 characters or less')
    .required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
})

const TeamForm = ({ team }) => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: team.name,
      email: team.email,
    },
    validationSchema,
    onSubmit: (values, formik) => {
      ApiClient.updateTeam(team.id, values)
        .then(() => navigate.push(`/teams`))
        .catch((err) => {
          const { data } = err.response
          if (data && data.errorCode === 'DUPLICATE_EMAIL') {
            return formik.setErrors({
              email: 'Email address is already registered for this challenge',
            })
          }

          formik.resetForm()
        })
        .finally(() => formik.setSubmitting(false))
    },
  })
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Field>
        <label htmlFor="name">Team Name</label>
        <TextInput
          id="name"
          name="name"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name && (
          <Error>{formik.errors.name}</Error>
        )}
      </Field>
      <Field>
        <label htmlFor="email">Email Address</label>
        <TextInput
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email && (
          <Error>{formik.errors.email}</Error>
        )}
      </Field>
      <Button type="submit" disabled={formik.isSubmitting || !formik.isValid}>
        {formik.isSubmitting ? '...' : 'Submit'}
      </Button>
      <Link to="/teams">Return to Team List</Link>
    </Form>
  )
}

export default TeamForm
