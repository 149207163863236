import getSubmissionType from './getSubmissionType'
import getSubmissionValue from './getSubmissionValue'

export default (submission) => {
  let createdAt, updatedAt, submittedAt
  if (submission.createdAt > 0) {
    createdAt = new Date(submission.createdAt).toLocaleString()
  }

  if (submission.updatedAt > 0) {
    updatedAt = new Date(submission.updatedAt).toLocaleString()
  }

  if (submission.submittedAt > 0) {
    submittedAt = new Date(submission.submittedAt).toLocaleString()
  }

  const type = getSubmissionType(submission)
  const value = getSubmissionValue(submission)

  return {
    createdAt,
    submittedAt,
    updatedAt,
    type,
    value,
  }
}
