import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

const Pager = ({ className, totalResults, ...props }) => {
  return (
    <Wrapper>
      <ResultCounter>Results: {totalResults || 0}</ResultCounter>
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        containerClassName={className}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        {...props}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 25px;
`

const ResultCounter = styled.p`
  text-align: center;
  color: blue;
`

const StyledPager = styled(Pager)`
  display: flex;
  justify-content: center;
  padding-inline-start: 0;
  padding: 0;

  li,
  a {
    display: inline-block;
    color: blue;
  }

  li.previous {
    a {
      width: 100px;
      text-align: center;
    }
    /* justify-self: left; */
    /* float: left; */
  }

  li.next {
    a {
      width: 100px;
      text-align: center;
    }
    /* float: right; */
  }

  a {
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;

    cursor: pointer;
    &:hover {
      background-color: blue;
      color: white;
    }
  }

  li.active a {
    background-color: blue;
    color: white;
  }
`

export default StyledPager
