import React from 'react'
import ReactDOM from 'react-dom/client'
import Modal from 'react-modal'

import App from './App'
import * as serviceWorker from './serviceWorker'

Modal.setAppElement(document.getElementById('root'))
ReactDOM.createRoot(document.getElementById('root')).render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
