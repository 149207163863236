import React, { Component } from 'react'
import ImagesScreen from './ImagesScreen'
import TeamsScreen from './TeamsScreen'
import TeamEditScreen from './TeamEditScreen'
import DuplicatesScreen from './DuplicatesScreen'
import IncompletesScreen from './IncompletesScreen'
import RecentSubmissionsScreen from './RecentSubmissionsScreen'
import styled from 'styled-components'
import { TeamContextProvider } from './TeamContext'
import 'fontsource-roboto/latin.css'
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Routes,
} from 'react-router-dom'

import GlobalStyles from './atoms/GlobalStyles'
import { Title } from './atoms/Text'
import ChallengePicker from './ChallengePicker'
import { ChallengeContextProvider } from './ChallengeContext'

class App extends Component {
  constructor(props) {
    super(props)
    window.axios = this.client
  }

  render() {
    return (
      <ChallengeContextProvider>
        <TeamContextProvider>
          <Container className="App">
            <GlobalStyles />
            <ChallengePicker />
            <StyledLink to="/images">
              <Title>Images</Title>
            </StyledLink>
            <StyledLink to="/teams">
              <Title>Teams</Title>
            </StyledLink>
            <StyledLink to="/duplicates">
              <Title>Potential Duplicates</Title>
            </StyledLink>
            <StyledLink to="/incompletes">
              <Title>Incomplete Submissions</Title>
            </StyledLink>
            <Routes>
              <Route exact path="/" element={<ImagesScreen />} />
              <Route path="/images" element={<ImagesScreen />} />
              <Route path="/teams/:id" element={<TeamEditScreen />} />
              <Route exact path="/teams" element={<TeamsScreen />} />
              <Route path="/duplicates" element={<DuplicatesScreen />} />
              <Route path="/incompletes" element={<IncompletesScreen />} />
              <Route path="/recent" element={<RecentSubmissionsScreen />} />
            </Routes>
          </Container>
        </TeamContextProvider>
      </ChallengeContextProvider>
    )
  }
}

const StagingBanner = styled.div`
  padding: 10px;
  text-align: center;
  background-color: #f7ffb8;
  text-transform: uppercase;
`

const Container = styled.div`
  margin: auto;
  margin-bottom: 100px;
  max-width: 1200px;
  padding-left: 10px;
  padding-right: 10px;
`

const StyledLink = styled(NavLink)`
  &.active > * {
    color: blue;
  }

  &:not(:first-child) > *:before {
    color: black;
    padding-right: 12px;
    content: ' /';
  }
`

const app = (props) => {
  return (
    <Router>
      <App {...props} />
    </Router>
  )
}

export default app
