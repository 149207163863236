import getSubmissionValue from './getSubmissionValue'

export default (team) => {
  if (!team) return '0'

  return (team.submissions || []).reduce((total, sub) => {
    total = total + getSubmissionValue(sub)
    return total
  }, 0)
}
