import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import Modal from 'react-modal'
import get from 'lodash/get'
import { Button, ButtonGroup } from './Buttons'
import modalStyle from './modalStyle'
import apiClient from '../apiClient'

const Image = (props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [sendEmail, setSendEmail] = useState(true)
  const [submission, setSubmission] = useState(null)

  const onApprove = async () => {
    setIsSaving(true)
    await props.onApprove(props.image)
    setIsSaving(false)
  }

  const onRevert = async () => {
    setIsSaving(true)
    await props.onRevert(props.image)
    setIsSaving(false)
  }

  const image = props.image

  let status
  if (image.approved === undefined || image.approved === null) {
    status = 'pending-approval'
  } else {
    status = image.approved ? 'approved' : 'rejected'
  }

  const under18 = get(image, 'team.contact.age', '') === '13-18'
  const teamName = get(image, 'team.name', '')
  const canReject = status === 'pending-approval' || status === 'approved'

  const getSub = async (id) => {
    const sub = await apiClient.getSubmission(id)
    setSubmission(sub)
  }

  useEffect(() => {
    if (image.submissionId === null) return

    getSub(image.submissionId)
  }, [image.submissionId])

  return (
    <ImgContainer $isSaving={isSaving}>
      {under18 && (
        <UnderAgeBanner age={get(image, 'team.contact.age', '')}>
          Under 18
        </UnderAgeBanner>
      )}

      <a href={image.url} target="_blank" rel="noreferrer">
        <Img src={image.url} alt="" />
      </a>
      <FlexSpacer />
      <ImgInfo>
        <a href={`mailto:${image.email}`}>{image.email}</a>
        <p>Team: {teamName}</p>
        <p>ID: {image.id}</p>
        <p>Type: {image.type}</p>
        <p>Status: {status}</p>
        {submission && (
          <>
            <p>Facebook: {submission.teamSocials?.facebook || 'N/A'}</p>
            <p>Instagram: {submission.teamSocials?.insta || 'N/A'}</p>
            <p>Twitter: {submission.teamSocials?.twitter || 'N/A'}</p>
          </>
        )}
        <a href={`/teams?q=${encodeURI(image.email)}`}>
          <p>View all for Team</p>
        </a>
      </ImgInfo>
      <ButtonGroup>
        {image.approved !== true && (
          <Button onClick={onApprove}>Approve</Button>
        )}
        {status === 'approved' && (
          <Button color="orange" onClick={onRevert}>
            Revert
          </Button>
        )}
        {status === 'reject' && <Button onClick={onRevert}>Revert</Button>}
        {canReject && (
          <Button color="red" onClick={() => setModalIsOpen(true)}>
            Reject
          </Button>
        )}
      </ButtonGroup>
      <Modal
        isOpen={modalIsOpen}
        style={modalStyle.centered}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <Formik
          initialValues={{
            rejectionEmail: sendEmail,
          }}
          onSubmit={(values) => {
            setIsSaving(true)
            const notifyUser = values.rejectionEmail === true
            return props
              .onReject(image, { notifyUser })
              .then(() => setIsSaving(false))
          }}
        >
          {() => (
            <Form>
              <ModalContent>
                <Header>Confirm Rejection</Header>
                <p>We can send an email to {image.email}.</p>
                <div>
                  <input
                    type="checkbox"
                    name="rejectionEmail"
                    id="notify-user"
                    checked={sendEmail}
                    onChange={() => setSendEmail(!sendEmail)}
                  />
                  <label htmlFor="notify-user">
                    Yes, send email upon rejection.
                  </label>
                </div>
                <ModalControls>
                  <Button onClick={() => setModalIsOpen(false)}>Cancel</Button>
                  <Button color="red" type="submit">
                    Reject
                  </Button>
                </ModalControls>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </ImgContainer>
  )
}

export default Image

export const Img = styled.img`
  display: block;
  max-height: 220px;
  object-fit: contain;
  width: 100%;
  margin-top: 20px;
`

const ModalContent = styled.div`
  display: grid;
  grid-gap: 2rem;
  max-width: 450px;
`

const ModalControls = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  margin-left: auto;
`

const Header = styled.h2`
  margin: 0;
`

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e7e7e7;
  border-radius: 5px;
  padding: 20px;
  opacity: ${(props) => (props.$isSaving ? 0.5 : 1)};
  pointer-events: ${(props) => (props.$isSaving ? 'none' : 'auto')};
  height: 100%;
  overflow: hidden;
`

const ImgInfo = styled.div`
  margin-top: 1em;
  display: inline-block;
  width: 300px;
  a {
    font-size: 18px;
    line-height: 1.2em;
  }

  p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 1.2em;
  }
`

const UnderAgeBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 20px;
  width: 100%;
  background-color: #fce9ac;
  font-weight: bold;
`

const FlexSpacer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`
