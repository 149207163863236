import React, { Component } from 'react'
import apiClient from './apiClient'
import orderBy from 'lodash/orderBy'
import ChallengeContext from './ChallengeContext'
import styled from 'styled-components'

class ChallengePicker extends Component {
  state = { challenges: [] }

  static contextType = ChallengeContext

  async componentDidMount() {
    const challenges = await apiClient.getChallenges()
    const sortedChallenges = orderBy(
      challenges,
      (c) => {
        return new Date(c.startDate).getTime()
      },
      ['desc']
    )

    this.setState({
      challenges: sortedChallenges,
    })
  }

  handleSelectChange = (e) => {
    this.context.setChallenge(e.target.value)
  }

  render() {
    const selectedChallenge = this.context.selectedChallenge
    const options = [...this.state.challenges]
    return (
      <Wrapper>
        <select
          name="challenge"
          value={selectedChallenge}
          onChange={this.handleSelectChange}
        >
          {options.map(
            (c) =>
              c.id && (
                <option key={c.id} value={c.id}>
                  {c.title}
                </option>
              )
          )}
        </select>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  font-size: 20px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  select {
    font-size: 20px;
  }
`

export default ChallengePicker
